@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-display: swap;
  font-family: 'Helvetica Neue LT Std';
  src: url('./fonts/helveticaneueltstd-roman.woff2') format('woff2'),
    url('./fonts/helveticaneueltstd-roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica Neue LT Std';
  src: url('./fonts/helveticaneueltstd-md.woff2') format('woff2'),
    url('./fonts/helveticaneueltstd-md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica Neue LT Std';
  src: url('./fonts/helveticaneueltstd-bd.woff2') format('woff2'),
    url('./fonts/helveticaneueltstd-bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica Neue LT Std';
  src: url('./fonts/helveticaneueltstd-blkex.woff2') format('woff2'),
    url('./fonts/helveticaneueltstd-blkex.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Arabic';
  src: url('./fonts/HelveticaNeueLTArabic-Roman.woff2') format('woff2'),
      url('./fonts/HelveticaNeueLTArabic-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.font-ar{
  font-family: 'HelveticaNeueLT Arabic';
}


 main{
  max-width: 1920px;
  margin: 0 auto;
} 

.gallery-item {
  clip-path: polygon(0 0, 100% 0, 100% 92%, 89% 100%, 0 100%);
}

.no-scroll {
  overflow: hidden;
  position: fixed;
}

select option {
  /* background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
  background: #23aa4a !important;
}

/* the slides */
.slick-slide {
  margin: 0 20px;
}

/* the parent */
.slick-list {
  margin: 0 -20px;
  padding: 0 10% 0 0 !important;
}

#untapped-slider .slick-list {
  padding: 0 40% 0 0 !important;
}

.slick-arrow:hover {
  cursor: pointer;
}

.slick-disabled.slick-arrow {
  cursor: not-allowed;
}

.slick-arrow svg {
  fill: green;
  stroke: blue;
}
.slick-disabled.slick-arrow.slick-left {
  background-image: url(images/arrow-left.svg) !important;
}
.slick-disabled.slick-arrow.slick-right {
  background-image: url(images/arrow-right.svg) !important;
}

/* .news-item{
  z-index: 1;
  position: relative;
}

a.news-item > article{
position: relative;
overflow: hidden;
} */

/* article::after{
  content: '';
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid rgba(128, 128, 128, 0.3);
  border-bottom: 10px solid rgba(128, 128, 128, 0.3);
} */

/* article::after {
  content: '';
  position: absolute;
  bottom: -21px;
  right: -1px;
  width: 50px;
  height: 50px;
  background-color: red;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  border-right: 1px solid rgba(128, 128, 128, 0.3);
  transform: rotate(-45deg) translate(50%, 50%);
  z-index: 99;
} */

/* slant news */



/* .news-item{
  width: auto;
  height: auto;
  position: relative;
  border: 4px solid #e74c3c;    
  color: #e74c3c;
  font: bold 18px/100px 'segoe ui';
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
} */
.news-item > article:before {
  content: '';
  position: absolute; bottom: 13px; right: 6px;
  width: 66px;
  height: 66px;
  transform: rotate(45deg);
  z-index: 1;
  border-right: 1px solid rgba(96,96,96,0.2);
}
/* .news-item:before,
.news-item:after{
  content: '';
  position: absolute; bottom: -4px; right: -4px;
} */
/* .news-item:before{    
  width: 47px;
  height: 0;    
  border-bottom: 4px solid #fff;
} */
/* .news-item:after{    
  width: 0;
  height: 47px;    
  border-right: 4px solid #fff;
} */


/* end slant news */

/* Mobile Menu Hamburger Styles. Ends at Line 234 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #c5d018;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #c5d018;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* Mobile Menu Hamburger Styles End */

#custom-carousel::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#custom-carousel::-webkit-scrollbar-track {
  background: grey;
  border: 7px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}

/* Handle */
#custom-carousel::-webkit-scrollbar-thumb {
  background: #c5d018;
  width: 324px;
  border: 1px solid #c5d018;
}

@media (min-width: 1024px) {
  .footer-path {
    clip-path: polygon(71% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
  }
}

/* bg for mobile */
@media (max-width:820px){
  #leading-the-field{
    /* background-image: url(images/dirt-bike-mobile.webp)!important; */
    /* background-position-x: center;
    background-position-y: bottom 4rem; */
    /* background-position: calc(50%) bottom_calc(4rem); */
    background-image: url(images/bg-mobile@2x.webp)!important;
  }
#racer-bg{
  background-image: url(images/racer-mobile@2x.webp)!important;
}

.footer-path{
  clip-path:polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
}

}


/* .snap-always:focus,
.snap-always:target,
.snap-always:active{
  opacity: 0.5;
} */

/* CustomSyles for Sizes for upto ipads */
@media (max-width: 1024px) {
  .slick-list {
    padding: 0 !important;
  }
}

@layer components {
  .site-wrapper {
    @apply mx-auto max-w-screen-3xl px-6 lg:px-150;
  }

  .nav-link {
    @apply cursor-pointer text-white transition-colors duration-300 hover:text-green;
  }

  .nav-link-mobile {
    @apply cursor-pointer font-bold text-white transition-colors duration-300 hover:text-green;
  }
}

.select2-container {
  @apply w-full appearance-none rounded-none border-0 border-b border-green bg-transparent pb-2 text-white !important;
}
.select2-container--default .select2-selection--single {
  @apply rounded-none border-0 bg-transparent !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered{
    @apply text-sm md:text-base;
  }

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff !important;
  /* font-size: 1rem; */
  padding-left: 0!important;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  @apply bg-green !important;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
  @apply bg-green !important;
}
.select2-results__option {
  @apply border-b border-[#c5d018] !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #c5d018 transparent transparent transparent !important;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #c5d018 transparent !important;
}

.select2-container.select2-container--open {
  border: none !important;
}

.select2-dropdown {
  @apply absolute z-50 float-left box-border block w-auto border-0 border-solid bg-green;
}

.select2-results {
  @apply bg-[#606060];
}

.select2-results__option {
  @apply text-white;
}
.select2-results__option--highlighted {
  background-color: greenyellow;
}

@media (width >= 1366px){
  .name-blue{
    min-height: 90px!important;
  }
}

@media screen and (min-width:1366px) and (max-width:1439px){
    #srj-board{
      padding-left:4rem;
    }
}

@media (width >= 1536px){
  #slider-container{
    max-width: 1440px!important;
    
  }
  #graphics{
    margin-left: 6rem;
  }
}

@media (width >= 1920px){
  #slider-container{
    max-width: 1576px!important;
  }
  #graphics{
    margin-left: 12rem;
  }
}

